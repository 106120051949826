.styled-table{
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    max-width: 800px;
    box-shadow: 0 0 20px rgba(136, 136, 136, 0.15);
    overflow: hidden;
}

.styled-table thead tr{
    background-color: #42A1FD;
    color: white;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 20px 28px;
}

.styled-table tbody tr{
    border-bottom: 1px solid #dadada ;
}

.styled-table tbody tr:nth-of-type(even){
    background-color: #f2f2f2;
}

.styled-table tbody tr:last-of-type{
    border-bottom: 1px solid #42A1FD ;
}

.btn {
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    font-size: 15px;
    margin: 4px 2px;
    cursor: pointer;
    transition-duration: 0.3s;
}

.btn-edit {
    background-color: #42A1FD;
}

.btn-edit:hover {
    background-color: #272727;
    color: rgb(255, 255, 255);
}

.btn1{
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    font-size: 15px;
    margin: 4px 2px;
    cursor: pointer;
    transition-duration: 0.3s;
    background:#42a1fd !important;
}
.div::-webkit-scrollbar
{
    width: 0;
}