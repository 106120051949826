.App {
  text-align: center;
  
  font-family: Arial, sans-serif;
}

#menwindow{
  background: #ffffff;
}
.container {

  display: flex;
  align-items: center;
  width: 47%;
  height: 30%;
  border-radius: 40px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-top: 2%;
  overflow: hidden;
}
.image {
 
  display: flex;
  margin: 5%;
  width: 125px;
  height: 125px;
  background-color: green;
  border-radius: 50%;
}

.grid-container {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  
}

.hospital-name {
  grid-row: 1;
  grid-column: 1 / span 3;
  text-align: left;
  font-weight: bold;
}



.trials-available {
  grid-row: 2;
  grid-column: 1;
  text-align: center;
}

.trials-available-value,
.patients-name-value,
.total-entries-value {
  text-align: center;
}

.patients-name {
  padding-left: 50px;
  grid-row: 2;
  grid-column: 2;
  text-align: center;
}

.total-entries {
  padding-left: 50px;
  grid-row: 2;
  grid-column: 3;
  text-align: center;
}

@media screen and (max-width: 900px){
.container {
  flex-direction: column;
  margin-top: 3%;
  display: flex;
  align-items: center;
 width: 340px;
 height: 340px;
 border-radius: 40px;
 box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
 overflow: hidden;
}

.image {
 
  display: flex;
  margin: 5%;

  width: 200px;
  height: 200px;
  background-color: green;
  border-radius: 50%;
}
.grid-container {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  padding: 10px;
}

.hospital-name {
  grid-row: 1;
  grid-column: 1 / span 2;
  text-align: left;
  font-weight: bold;
}

.trials-available {
  padding-left: 0px;
  grid-row: 2;
  grid-column: 1;
  text-align: center;
}

.trials-available-value,
.patients-name-value,
.total-entries-value {
  text-align: center;
}

.patients-name {
      padding-left: 0px;
  grid-row: 3;
  grid-column: 1;
  text-align: center;
}

.total-entries {
  padding-left: 0px;
  grid-row: 4;
  grid-column: 1;
  text-align: center;
}
}
