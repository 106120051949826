.procimage-container {
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    height: 100vh; 
    margin-top: 200px;
}

.procimage {
    padding: 40px;
    max-width: 75%; 
    max-height: 75%;
}