
#patientinfo-page {
    width: 600px;
    padding: 8% 0 0;
    margin: auto;
  }
  #patientinfo-page #form #login{
    margin-top: -31px;
  margin-bottom: 26px;
  }
  #patientinfo-form form {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    max-width: 600px;
    margin: 0 auto 100px;
    padding: 45px;
    text-align: center;
    border-radius: 1em;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  }
  #patientinfo-form form input {
    font-family: "Roboto", sans-serif;
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 1em;
  }
  #form button {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background-color: rgb(32,177,255);
    width: 100%;
    border: 0;
    padding: 15px;
    color: #FFFFFF;
    border-radius: 1em;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
  }
  