@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Titillium Web", sans-serif;
}

:root {
	--mainColor: #f5f5f5;
	--mainColorLight: #5767aa;
	--secondaryColor: #db2b39;
	--textColor: #020202;
    
}

header {
    border-radius: 2em;
	display: flex;
    margin: auto;
    margin-top: 2em;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}




nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
	
}

nav a:hover {
	color: var(--secondaryColor);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
	
}

.no-bullet
{
	list-style-type:none ;
}

/* Default styling for navigation links */
.nav-link {
	
	text-decoration: none;
	color: #333;
	list-style-type: none;
  }
  
  /* Active link styling */
  .nav-link.active {
	font-weight: bold;
	color: #007bff; /* Blue color for active link */
	pointer-events: none;
	list-style-type: none;
	
  }
  







@media only screen and (max-width: 500px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		z-index: 2;
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
	}

	header .responsive_nav {
		transform: translateY(100vh);
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
}