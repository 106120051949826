@import url('https://fonts.googleapis.com/css2?family=B612:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=B612:wght@700&display=swap');

#random-div1{
    margin-bottom: 5em;
}

#random-page {
    width: 360px;
    padding: 5% 0 0;
    margin: auto;
    
  }

#random-header{
    margin-top: -3em;
    position: relative;
    font-family: 'B612', sans-serif;
}

#random-form2{
    margin-top: 1em;
    position: relative;
    margin-bottom: -1em;
}

  #random-form1 {
    position: relative;
    z-index: 1;
    background: #ffffff;
    border-radius: 1em;
    max-width: 3600px;
    margin: 0 auto 20px;
    padding: 80px;
    text-align: center;
    box-shadow: 0 0 20px 0 rgba(11, 11, 11, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

#random-nonedit{
 font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 1;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 1em;
}

#random-form2 {
  display: flex; /* Add flexbox display */
  justify-content: space-between; /* Add space between buttons */
  gap: 35px;
}

#yes-btn {
  margin-bottom: -2em;
  text-transform: uppercase;
  width: 80%;
  outline: none; /* Corrected from outline: 10; */
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(52, 95, 194);
  border-radius: 1em;
  transition: 0.5s;
  cursor: pointer;
  text-decoration: none;
  padding: 13px;
}

#yes-btn:hover {
  background-color: rgb(52, 95, 194);
  color: white;
}

#no-btn {
  margin-bottom: -2em;
  text-transform: uppercase;
  width: 80%;
  outline: none; /* Corrected from outline: 10; */
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(52, 95, 194);
  border-radius: 1em;
  transition: 0.5s;
  cursor: pointer;
  text-decoration: none;
  padding: 13px;
}

#no-btn:hover {
  background-color: rgb(52, 95, 194);
  color: white;
}

#yes-btn.selected {
  color: green;
}

#no-btn.selected {
  color: red;
}

#randomize-btn{
  margin-bottom: -2em;
  text-transform: uppercase;
  width: 100%;
  outline: 10;
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(52, 95, 194);
  border-radius: 1em;
  transition: 0.5s;
  cursor: pointer;
  text-decoration: none;
  padding: 13px;
}


#randomize-btn:hover {
   background-color: #0E5C95;
   color: white;
}

#can-btn{
    text-transform: uppercase;
    width: 30%;
    outline: 10;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(52, 95, 194);
    border-radius: 1em;
    transition: 0.5s;
    cursor: pointer;
    text-decoration: none;
    padding: 13px;
}

#can-btn:hover {
   background-color: #f42727;
   color: white;
}


#entrybtn{
  visibility: hidden;
  text-transform: uppercase;
  margin-top: 10px;
  width: 50%;
  outline: 10;
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(52, 95, 194);
  border-radius: 1em;
  transition: 0.5s;
  cursor: pointer;
  text-decoration: none;
  padding: 13px;
}

#entrybtn:hover {
 background-color: rgb(20, 150, 0);
 color: white;
}

#smalling
{
    width: 10%;
   
}