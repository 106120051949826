@import url('https://fonts.googleapis.com/css2?family=B612:wght@700&display=swap');

#login-div1{
    margin-bottom: 5em;
}

#login-logo1{
    position: relative;
    width: 360px;
    margin-top: 1%;
    margin-left: 5%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'B612', sans-serif;
}

#login-marq{
    position: relative;
    margin-top: 0.5%;
    height: 6.5%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0.188em 1.550em rgb(220, 220, 220);
    border: 2px solid rgb(255, 255, 255);
    display: flex;
    align-items: center;
    font-family: 'B612', sans-serif;
}

#login-page {
    width: 360px;
    padding: 5% 0 0;
    margin: auto;
  }

#login-header{
    margin-top: -2em;
    position: relative;
}

#login-form2{
    margin-top: 2em;
    position: relative
}

  #login-form1 {
    position: relative;
    z-index: 1;
    background: #0E5C95;
    border-radius: 1em;
    max-width: 3600px;
    margin: 0 auto 20px;
    padding: 45px;
    text-align: center;
    box-shadow: 0 0 20px 0 rgba(11, 11, 11, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

#login-form1 input {
    margin-top: 100%;
    font-family: "Roboto", sans-serif;
    outline: 1;
    background: #f4f4f4;
    width: 100%;
    border: 0;
    margin: 0 0 20px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 1em;
    padding-left: 20px;
    outline-color: #108554;

  }
  
  #login-btn{
    text-transform: uppercase;
    width: 100%;
    outline: 10;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(52, 95, 194);
    border-radius: 1em;
    transition: 0.5s;
    cursor: pointer;
    text-decoration: none;
    padding: 13px;
}

#login-btn:hover {
   background-color: #0E5C95;
   color: white;
}